<template>
  <div class="my-login-page">
    <section class="h-100">
      <div class="my-login-page-inner d-flex align-items-center justify-content-between" style="min-height: 100vh;max-width:82%">
        <div class="brand-name">
          <h6>
            ABAI DIGITAL
          </h6>
        </div>
        <div class="row justify-content-md-center h-100">
          <div class="card-wrapper">
            <div class="card fat">
              <div class="card-body">
                <h4 class="card-title">Восстановление доступа</h4>

                <!-- start form enter email -->
                <form v-if="!emailEntered" @submit.prevent="checkingEmail" class="my-login-validation" novalidate="">
                  <p>
                    Укажите email, который вы использовали при регистрации.
                  </p>
                  <div v-if="checkingEmailErrors.length" class="alert alert-danger" role="alert">
                    <p v-for="(error, field) in checkingEmailErrors" :key="field">
                      {{ error }}.
                    </p>
                    <p>
                      В случае если вы не имеете доступа к email,
                      то напишите нам на support@uib.kz и укажите ваш логин (баркод) с новой почтой.
                    </p>
                  </div>
                  <div class="form-group col-md-12 mt-4">
                    <label for="email">Email</label>
                    <input id="email" type="email" class="form-control" name="email"
                           v-model="emailForm.email" required autofocus>
                  </div>
                  <div class="form-group col-md-12 mt-4">
                    <button :disabled="loadingForButton" class="btn btn-pill text-white btn-block btn-primary auth-btn">
                      {{ loadingForButton ? 'Подождите' : 'Продолжить' }}
                    </button>
                  </div>
                  <div class="mt-4 text-center">
                    Вернуться на страницу
                    <router-link to="/login" class="forgot-pass">Входа</router-link>
                  </div>
                </form>
                <!-- end form enter email -->


                <!-- start form enter recoveryCode -->
                <form v-else-if="!recoveryCodeEntered" @submit.prevent="checkingRecoveryCode"
                      class="my-login-validation"
                      novalidate="">
                  <p>
                    Введите код который пришел вам на почту {{ emailForm.email }}
                  </p>
                  <div v-if="checkingRecoveryCodeErrors.length" class="alert alert-danger" role="alert">
                    <p v-for="(error, field) in checkingRecoveryCodeErrors" :key="field">
                      {{ error }}
                    </p>
                  </div>
                  <div class="form-group col-md-12 mt-2">
                    <label for="pin">Код</label>
                    <input id="pin" type="number" class="form-control" name="pin"
                           v-model="recoveryCodeForm.pin" required autofocus>
                  </div>

                  <div class="form-group col-md-12 mt-4">
                    <button :disabled="loadingForButton" class="btn btn-pill text-white btn-block btn-primary auth-btn">
                      {{ loadingForButton ? 'Подождите' : 'Продолжить' }}
                    </button>
                  </div>
                  <div class="mt-4 text-center">
                    Вернуться на страницу
                    <router-link to="/login" class="forgot-pass">Входа</router-link>
                  </div>
                </form>
                <!-- end form enter recoveryCode -->


                <!-- start form resetPassword -->
                <form v-else @submit.prevent="resetPassword" class="my-login-validation" novalidate="">
                  <p>
                    Введите новый пароль для аккаунта {{ account }}
                  </p>
                  <div v-if="resetPasswordErrors.length" class="alert alert-danger" role="alert">
                    <p v-for="(error, field) in resetPasswordErrors" :key="field">
                      {{ error }}
                    </p>
                  </div>

                  <div class="password-div form-group col-md-12 mt-4">
                    <label for="password" class="d-inline">Новый пароль</label>
                    <i class="fas fa-question d-inline" id="password_info"></i>
                    <div class="alert alert-info password-info" role="alert">
                      <p>Требования к паролю:</p>
                      <p>Не менее 9 символов' </p>
                      <p>Хотя бы одна заглавная буква' </p>
                      <p>Хотя бы одна строчная буква' </p>
                      <p>Хотя бы одна цифра.'</p>
                    </div>
                    <input type="password" id="password" class="form-control" name="password"
                           v-model="resetPasswordForm.password" required data-eye>
                    <div style="text-align: center" class="password-checker-info">
                      <span v-if="scorePassword <= 25 && scorePassword > 0" class="text-danger">
                        {{ passwordProtection }}
                      </span>
                      <span v-else-if="scorePassword < 75 && scorePassword > 25"
                            class="bg-light text-danger">{{ passwordProtection }}</span>
                      <span v-else-if="scorePassword >= 75" class="text-success">{{ passwordProtection }}</span>
                      <span v-else class="text-info">{{ passwordProtection }}</span>

                    </div>
                    <div class="invalid-feedback">
                      Введите пароль
                    </div>
                  </div>

                  <div class="form-group col-md-12 mt-4">
                    <label for="password_repeat">Повторите новый пароль</label>
                    <input id="password_repeat" type="password" class="form-control" name="password_repeat"
                           v-model="resetPasswordForm.password_repeat" required data-eye>
                  </div>


                  <div class="form-group col-md-12 mt-4">
                    <button :disabled="loadingForButton||disabledCheck===false"
                            class="btn btn-pill text-white btn-block btn-primary auth-btn">
                      {{ loadingForButton ? 'Подождите' : 'Продолжить' }}
                    </button>
                  </div>
                  <div class="mt-4 text-center">
                    Вернуться на страницу
                    <router-link to="/login" class="forgot-pass">Входа</router-link>
                  </div>
                </form>
                <!-- end form resetPassword -->

              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>


</template>
<script>
import authService from "@/services/auth.service"

export default {
  name: "Login",
  data() {
    return {
      emailForm: {
        email: ''
      },
      recoveryCodeForm: {
        pin: '',
        user_id: ''
      },
      resetPasswordForm: {
        pin: '',
        password: '',
        password_repeat: ''
      },
      emailEntered: false,
      recoveryCodeEntered: false,
      checkingEmailErrors: [],
      checkingRecoveryCodeErrors: [],
      resetPasswordErrors: [],
      errors: null,
      loadingForButton: false,
      account: '',
      show: false,

    }
  },
  computed: {
    scorePassword() {
      let score = 0
      if (this.resetPasswordForm.password === '') return score;
      let letters = {}
      for (let i = 0; i < this.resetPasswordForm.password.length; i++) {
        letters[this.resetPasswordForm.password[i]] = (letters[this.resetPasswordForm.password[i]] || 0) + 1
        score += 6.0 / letters[this.resetPasswordForm.password[i]]
      }
      let variations = {
        digits: /\d/.test(this.resetPasswordForm.password),
        lower: /[a-z]/.test(this.resetPasswordForm.password),
        upper: /[A-Z]/.test(this.resetPasswordForm.password),
        special: /\W/.test(this.resetPasswordForm.password)
      };
      let variationsCount = 0;
      for (let check in variations) {
        variationsCount += (variations[check] === true) ? 1 : 0
      }
      score += (variationsCount - 1) * 12;
      return parseInt(score);
    },
    passwordProtection() {
      if (this.scorePassword < 75 && this.scorePassword > 0) {
        return 'Пароль не соответствует требованиям безопасности'
      }
      if (this.scorePassword >= 75) {
        return 'Хороший пароль'
      } else {
        return ''
      }
    },
    disabledCheck() {
      return this.scorePassword >= 75;
    },
  },
  methods: {
    async checkingEmail() {
      if (!this.validateEmail(this.emailForm.email)) {
        this.checkingEmailErrors = ['Некорректный email']
        return
      }
      this.loadingForButton = true
      const res = await authService.checkingEmail(this.emailForm)
      this.loadingForButton = false

      if (typeof res.data == 'string') {
        this.checkingEmailErrors = [res.data]
        return
      }
      this.emailEntered = true
      this.recoveryCodeForm.user_id = res.data.user_id
    },
    async checkingRecoveryCode() {
      if (this.recoveryCodeForm.pin.toString().length != 6) {
        this.checkingRecoveryCodeErrors = ['Некорректный пин-код']
        return
      }

      this.loadingForButton = true
      const res = await authService.checkingRecoveryCode(this.recoveryCodeForm)
      this.loadingForButton = false

      if (res.data == 'Не правильный пин-код' || res.data == 'Пустое значение') {
        this.checkingRecoveryCodeErrors = [res.data]
        return
      }
      this.account = res.data
      this.recoveryCodeEntered = true
    },
    async resetPassword() {
      if (this.resetPasswordForm.password !== this.resetPasswordForm.password_repeat) {
        this.resetPasswordErrors = ['Пароли не совпадают']
        return
      }

      const pin = this.recoveryCodeForm.pin;
      const password = this.resetPasswordForm.password;

      this.loadingForButton = true
      const res = await authService.resetPassword({pin, password})
      this.loadingForButton = false

      const text = 'Пароль успешно изменен'

      if (res.data !== text) {
        this.resetPasswordErrors = [res.data]
        return
      }

      this.$message({text})
      this.$router.push({name: 'login'})
    },
    validateEmail(email) {
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      return re.test(String(email).toLowerCase());
    }
  }
}
</script>


<style lang="css" scoped>
.password-eye {
  /*position: absolute;*/
  /*top: 6px;*/
  position: relative;
  top: 32px;
  right: 52px;
  z-index: 11;
  cursor: pointer;
}

input#password {
}

small {
  display: block;
  color: #888;
}

p {
  margin-bottom: 2px;
}

#password_info:hover + .password-info {
  display: block;
  position: absolute;
  top: 35%; /* Абсолютное позиционирование */
  width: 80%;
  z-index: 15;

}

.password-info {
  display: none;
}

.password-checker-info {
  display: block;
  position: absolute;
}
</style>
